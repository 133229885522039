import React from 'react'
import BBCLogo from '../../resources/BBCLogo.svg'
import glamourLogo from '../../resources/glamourLogo.svg'
import metroLogo from '../../resources/metroLogo.svg'
import PBNLogo from '../../resources/PBNLogo.svg'
import sundayTimesLogo from '../../resources/sundayTimesLogo.svg'
import veganReviewLogo from '../../resources/veganReviewLogo.svg'
import vevolutionLogo from '../../resources/vevolutionLogo.svg'
import viceLogo from '../../resources/viceLogo.svg'
import './index.scss'

export const Press = () => {
    return (
        <div className="press-page">
            <div className="press-page__content">
                <div className="common-main-page-title">
                    Want to <br /> know more?
                </div>
                <div className="press-page__subtitle">
                    For all media enquiries
                </div>

                <a
                    className="common-button press-page-button"
                    href="mailto:info@grazerapp.com"
                >
                    GET IN TOUCH
                </a>

                <span className="press-page__mentioned">MENTIONED BY</span>

                <div className="press-page__logo-container">
                    <div>
                        <img alt="vice" src={viceLogo} className="vice" />
                        <img alt="PBN" src={PBNLogo} className="PBN" />
                        <img alt="metro" src={metroLogo} className="metro" />
                        <img alt="vevolution" src={vevolutionLogo} className="vevo" />
                    </div>

                    <div>
                        <img alt="glamour" src={glamourLogo} className="glamour" />
                        <img alt="veganreview" src={veganReviewLogo} className="vegan" />
                        <img alt="bbc" src={BBCLogo} className="bbc" />
                        <img alt="sundaytimes" src={sundayTimesLogo} className="sunday" />
                    </div>
                </div>
            </div>
        </div>
    )
}
